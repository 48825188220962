<template>
    <v-container>
        <v-row no-gutters>
            <v-col>
                <div>
                    <v-skeleton-loader type="table" v-if="api.isLoading" ref="skeleton">

                    </v-skeleton-loader>
                    <v-data-table
                    :search="searchCompanies"
                    :items="data"
                    :headers="dataHeader"
                    class="elevation-1"
                    dense 
                    v-if="data!=null"
                    >  
                    <template v-slot:item.Index="{item}">
                        {{ data.indexOf(item)+1 }}
                    </template>
                    <template v-slot:item.whatssap_name="{item}">
                        <router-link :to="{name:'PageWhatssapTemplateUpdate',params:{'id':item.whatssap_id}}" v-if="item.user_id==$store.getters.getUserId">
                            {{ item.whatssap_name }}
                        </router-link>             
                        <span v-else>
                            {{item.whatssap_name}}
                        </span>               
                    </template>
                    <template v-slot:top>
                        <v-toolbar height="auto" class="py-3" flat>
                            <v-row no-gutters>  
                                <v-col class="mt-3 mx-3">
                                    <v-row class="mb-3">
                                        <v-toolbar-title class="font-weight-bold text-h4 my-3">
                                            Whatssap

                                        </v-toolbar-title>
                                        <v-spacer>
                                        </v-spacer>
                                        <v-btn color="primary" :to="{name:'PageWhatssapTemplateCreate'}">
                                            New
                                        </v-btn>
                                    </v-row>
                                </v-col>
                            </v-row>

                        </v-toolbar>
                    </template>
                    </v-data-table> 
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default{
    data:()=>({
        
        searchCompanies:null,
        renewalStatusFiltered:null,
        companiesRenewal : null,
        filteredCompanies:null,
        dataHeader :[
            {
                text:'#',
                value:'Index',
                align:'start',
                sortable:false,
            },
            {
                text:'Name',
                value:'whatssap_name',
            },
            {
                text:'Message',
                value:'message',
            },
            {
                text:'Created By',
                value:'user_name',
            },
            {
                text:'Accessibility',
                value:'accessibility',
            },
        ],
        data:null,
        api : {
                isLoading:false,
                isError:false,
                error:null,
                url:null,
            },
        
    }),
    created(){
        this.api.callbackReset = () =>
        {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) =>
        {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) =>
        {
            if(resp.class=="getAllWhatssapTemplate"){
                let allowedRoles = ["developer","operation"];
                let tempData = this.$_.clone(resp.data);
                if(!allowedRoles.includes(this.$store.getters.getRole)){
                    let userId = this.$store.getters.getUserId;
                    let tempFilter = tempData.filter(function(item){
                    
                        if(item.accessibility==="public"){
                            return true;
                        }
                        if(parseInt(item.user_id)==parseInt(userId) && item.accessibility==="private"){

                            return true;
                        }
                    })
                    tempData = tempFilter;
                }
                this.data = tempData;
            }
            this.api.isLoading = false;

        }
    },
    mounted(){
        this.fetch();
    },
    methods:{
        fetch(){
            let fetchWhatssapTemplateListApi = this.fetchWhatssapTemplateList();
            this.$api.fetch(fetchWhatssapTemplateListApi);
        },
        fetchWhatssapTemplateList(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/whatssap/template";
            return tempApi;
        },
        fetchRenewalCompanies(){
            let tempApi = this.$_.clone(this.api);
            tempApi.url = process.env.VUE_APP_SERVER_API+'/renewal/status';
            tempApi.method = "GET";
            return tempApi;
        },
        filterRenewalStatus(){
            let tempCompanies = this.$_.clone(this.companiesRenewal);
            let filter = this.$_.clone(this.renewalStatusFiltered);

            let tempFilteredCompanies = tempCompanies.filter(function(item){
                if(filter==="Unreachable"){
                    return (item.renewal_status==="Unreachable");
                }
                if(filter==="Pending"){
                    return (item.renewal_status==="Pending");
                }
                if(filter==="Rejected"){
                    return (item.renewal_status==="Rejected");

                }
                if(filter==="Confirm"){
                    return (item.renewal_status==="Confirm");
                }
                if(filter===null){
                    return true;
                }
            })
            this.filteredCompanies = tempFilteredCompanies;
        },
    }
}
</script>